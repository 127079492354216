import {
  Box,
  Checkbox,
  Flex,
  Spinner,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import BlackboardTemplateImage from "components/ui/BlackboardTemplateImage";
import { ContentType, SubItemKey } from "constants/enum";
import { BLACKBOARD_CONTAINER_ID } from "constants/styleProps";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { Level } from "interfaces/models";
import { Blackboard as IBlackboard } from "interfaces/models/blackboard";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { User } from "interfaces/models/user";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { WSMessage } from "interfaces/models/websocket";
import { GetContentLog } from "models/dataLog";
import { memo, useMemo, useRef } from "react";
import { useBlackboard } from "./hooks";
import Photo from "./Photo";

interface Props {
  subItem?: DocumentSubItemDTO;
  users: { [key: string]: UserDTO };
  documentItemSelected?: DocumentItemDTO;
  documentTemplate?: DocumentTemplate;
  documentCategorySelected?: DocumentCategoryDTO;
  dataBlackboardDefault?: Partial<IBlackboard>;
  currentUser?: User | null;
  familyInstance: FamilyInstanceDTO;
  levelSelected?: Level;
  listOptionFloor?: { label: string; value: string }[];
  listOptionUsers?: { label: string; value: string }[];
  listPartnerCompanies?: { label: string; value: string }[];
  dataProjectDetail?: DataProjectModel;
  isDisabled?: boolean;
  projectName?: string;
  isLoadedBlackboard: boolean;
  dataBlackBoards: IBlackboard[];
  webSocketMessages: WSMessage[];
  insertSubItemLog: (params: GetContentLog) => Promise<void>;
}

const Blackboard = (props: Props) => {
  const {
    users,
    subItem,
    currentUser,
    documentTemplate,
    documentItemSelected,
    documentCategorySelected,
    dataBlackboardDefault,
    familyInstance,
    levelSelected,
    listPartnerCompanies,
    listOptionFloor,
    listOptionUsers,
    isDisabled,
    projectName,
    isLoadedBlackboard,
    dataBlackBoards,
    webSocketMessages,

    insertSubItemLog,
  } = props;

  const {
    dataBlackboard,
    isShowBlackboard,
    loadingGetBlackboardData,
    blackboardTemplate,
    blackboardPosition,
    isOpenSelectBlackboardPosition,
    isFocusBlackboard,

    setIsFocusBlackboard,
    handleChangeItemData,
    handleChangeBlackboardData,
    handleUpdateDataBlackboardState,
    handleOpenPositionBlackboardModal,
    handleClosePositionBlackboardModal,
    handleSaveBlackboardData,
  } = useBlackboard({
    subItem,
    documentTemplate,
    documentItemSelected,
    isLoadedBlackboard,
    dataBlackBoards,
    dataBlackboardDefault,
    isDisabled,
    webSocketMessages,
    insertItemLog: insertSubItemLog,
  });

  const blackboardTemplateProps = useMemo(
    (): iBlackboardTemplateProps => ({
      levelSelected,
      familyInstance,
      blackboardTemplate,
      listOptionUsers,
      listOptionFloor,
      listPartnerCompanies,
      projectName,
      documentCategorySelected,
      documentSubItem: subItem,
    }),
    [
      documentCategorySelected,
      familyInstance,
      blackboardTemplate,
      listOptionUsers,
      listOptionFloor,
      listPartnerCompanies,
      levelSelected,
      projectName,
      subItem,
    ]
  );

  const blackboardContainerRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    enabled: isFocusBlackboard,
    ref: blackboardContainerRef,
    handler: handleSaveBlackboardData,
  });

  return (
    <>
      {blackboardTemplate?.id && (
        <Checkbox
          mb="0.4rem"
          isChecked={isShowBlackboard}
          isDisabled={isDisabled}
          sx={{
            ".chakra-checkbox__control[data-checked]": {
              backgroundColor: "var(--primary-color)",
              borderColor: "var(--primary-color)",
            },
          }}
          onChange={handleChangeItemData(SubItemKey.IS_SHOW_BLACKBOARD)}
        >
          黒板の表示
        </Checkbox>
      )}

      {isShowBlackboard && blackboardTemplate && (
        <>
          <Text textAlign="left" mb="0.8rem">
            黒板情報
          </Text>

          {loadingGetBlackboardData && (
            <Flex
              justifyContent="center"
              alignItems="center"
              position="absolute"
              inset="0px"
              backgroundColor="rgba(0,0,0,0.2)"
              zIndex={10}
            >
              <Spinner color="blue.500" size="xl" mt="1rem" />
            </Flex>
          )}

          <Box
            id={BLACKBOARD_CONTAINER_ID}
            border={`2px solid ${
              isFocusBlackboard ? "#009BE0" : "transparent"
            }`}
            mb="2rem"
            ref={blackboardContainerRef}
            onClick={setIsFocusBlackboard.on}
          >
            <Box
              position="relative"
              zIndex={12}
              width="100%"
              style={{
                aspectRatio: "1.333",
              }}
            >
              <BlackboardTemplateImage
                contentType={ContentType.BLACKBOARD_TEMPLATE}
                data={blackboardTemplate}
                blackboardData={dataBlackboard}
                onChangeBlackboardData={handleChangeBlackboardData}
                blackboardTemplateProps={blackboardTemplateProps}
                isDisabled={isDisabled}
              />
            </Box>
          </Box>
        </>
      )}

      <Photo
        currentUser={currentUser}
        users={users}
        subItem={subItem}
        dataBlackboard={dataBlackboard}
        blackboardTemplate={isShowBlackboard ? blackboardTemplate : undefined}
        blackboardPosition={blackboardPosition}
        documentItemSelected={documentItemSelected}
        isOpenSelectBlackboardPosition={isOpenSelectBlackboardPosition}
        blackboardTemplateProps={blackboardTemplateProps}
        isDisabled={isDisabled}
        handleUpdateDataBlackboardState={handleUpdateDataBlackboardState}
        onOpenPositionBlackboardModal={handleOpenPositionBlackboardModal}
        onClosePositionBlackboardModal={handleClosePositionBlackboardModal}
        insertSubItemLog={insertSubItemLog}
      />
    </>
  );
};

export default memo(Blackboard);
