import { useBoolean } from "@chakra-ui/react";
import { blackboardApi, documentItemApi } from "apiClient/v2";
import { SubItemKey } from "constants/enum";
import { S3_PATH } from "constants/s3";
import { BLACKBOARD_CONTAINER_ID } from "constants/styleProps";
import { MessageType } from "constants/websocket";
import { useRoles } from "hooks/usePermission";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { Blackboard } from "interfaces/models/blackboard";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { SizePosition } from "interfaces/models/rnd";
import { WSMessage } from "interfaces/models/websocket";
import { GetContentLog } from "models/dataLog";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch } from "react-redux";
import {
  setDataBlackboard,
  setDataBlackboards,
  updateDocumentItem,
} from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { uuid } from "utils/common";
import { snapShotBlackboard } from "utils/data-logs";
import { isLatestByUpdatedTime } from "utils/date";
import { base64ToFile, uploadFileToS3 } from "utils/file";
import { checkDiffTwoObject } from "utils/object";

interface Props {
  subItem?: DocumentSubItemDTO;
  documentTemplate?: DocumentTemplate;
  isLoadedBlackboard: boolean;
  dataBlackBoards: Blackboard[];
  dataBlackboardDefault?: Partial<Blackboard>;
  documentItemSelected?: DocumentItemDTO;
  isDisabled?: boolean;
  webSocketMessages: WSMessage[];
  insertItemLog?: (params: GetContentLog) => Promise<void>;
}

let timeoutChangeItemData: any;

const useBlackboard = (props: Props) => {
  const {
    subItem,
    documentTemplate,
    dataBlackBoards,
    isLoadedBlackboard,
    documentItemSelected,
    dataBlackboardDefault,
    isDisabled,
    webSocketMessages,
    insertItemLog,
  } = props;

  const { socket } = useForgeViewerContext();
  const [isFocusBlackboard, setIsFocusBlackboard] = useBoolean();
  const [isOpenSelectBlackboardPosition, setIsOpenSelectBlackboardPosition] =
    useBoolean();
  const [dataBlackboard, setDataBlackboardState] = useState<
    Blackboard | undefined
  >();
  const [loadingGetBlackboardData, setLoadingGetBlackboardData] =
    useBoolean(true);
  const dispatch = useDispatch();

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const dataBlackBoardDefaultRef = useRef<Partial<Blackboard> | undefined>();

  const dataBlackBoardRef = useRef<Blackboard | undefined>(dataBlackboard);
  const dataBlackBoardsRef = useRef<Blackboard[]>(dataBlackBoards);
  const tempBlackboardChangedRef = useRef(dataBlackboard);
  const prevDataBlackboardRef = useRef<Blackboard | undefined>(dataBlackboard);

  const { isTakasagoGroup } = useRoles();

  const dataBlackboardRedux = useMemo(
    () => dataBlackBoards.find((data) => data.id === dataBlackboard?.id),
    [dataBlackboard?.id, dataBlackBoards]
  );

  const blackboardTemplate = useMemo(
    () => documentTemplate?.blackboardTemplateDetail,
    [documentTemplate?.blackboardTemplateDetail]
  );

  const isShowBlackboard = useMemo(
    () => !!blackboardTemplate?.id && subItem?.isShowBlackboard !== false,
    [blackboardTemplate?.id, subItem?.isShowBlackboard]
  );

  const blackboardPosition = useMemo(() => {
    const pos = subItem?.blackboardImagePosition as SizePosition;

    return [
      pos?.x ?? 0,
      pos?.y ?? 0,
      pos?.width ?? 0,
      pos?.imgWidth ?? 0,
      pos?.height ?? 0,
      pos?.imgHeight ?? 0,
    ];
  }, [subItem?.blackboardImagePosition]);

  dataBlackBoardDefaultRef.current = dataBlackboardDefault;
  dataBlackBoardRef.current = dataBlackboard;

  useEffect(() => {
    if (dataBlackboardRedux?.id === dataBlackboard?.id) {
      setDataBlackboardState(dataBlackboardRedux);
    }
  }, [dataBlackboardRedux, dataBlackboard?.id]);

  useEffect(() => {
    if (!webSocketMessages.length) {
      return;
    }

    webSocketMessages.forEach((e) => {
      const { type, data } = e;

      if (
        type === MessageType.UPDATE_BLACKBOARD &&
        data.id === dataBlackBoardRef.current?.id
      ) {
        setDataBlackboardState((prev) => {
          if (!isLatestByUpdatedTime(prev, data)) return prev;

          return { ...prev, ...data };
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages]);

  // init data for blackboard
  useEffect(() => {
    (async () => {
      if (!subItem?.id || !documentItemSelected?.id || !isLoadedBlackboard) {
        return;
      }

      const now = new Date();
      const blackboardId = subItem?.blackboardId;
      let dataBlackBoard: Blackboard | undefined;
      if (blackboardId) {
        dataBlackBoard = dataBlackBoardsRef.current?.find(
          (item) => item.id === blackboardId
        );
        if (!dataBlackBoard) {
          const { data } = await blackboardApi.getBlackboardList({
            documentCategoryId: documentItemSelected.documentCategoryId,
          });

          dispatch(setDataBlackboards(data));
          dataBlackBoard = data.find((item) => item.id === blackboardId);
        }
      } else if (dataBlackBoardDefaultRef.current && isTakasagoGroup) {
        const requestId = uuid();

        const { data } = await blackboardApi.createBlackboard({
          ...dataBlackBoardDefaultRef.current,
          subItemId: subItem.id,
          requestId,
        });

        dataBlackBoard = data;

        if (dataBlackBoard?.id) {
          const itemItem = structuredClone(documentItemSelected);
          updateElementInArray({
            array: itemItem?.subItems || [],
            keyIndex: SubItemKey.ID,
            element: {
              ...subItem,
              updatedAt: now,
              blackboardId: dataBlackBoard.id,
            } as DocumentSubItemDTO,
          });
          socket.updateDocItem(itemItem, {
            subItems: itemItem?.subItems,
            updatedAt: dataBlackBoard.updatedAt,
          });
          socket.updateBlackboard(
            itemItem.level!,
            dataBlackBoard.id,
            dataBlackBoard
          );
          dispatch(updateDocumentItem(itemItem));
          dispatch(setDataBlackboard(dataBlackBoard));
        }
      }

      setDataBlackboardState(dataBlackBoard);
      setLoadingGetBlackboardData.off();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subItem?.id,
    documentItemSelected?.id,
    isLoadedBlackboard,
    socket,
    isTakasagoGroup,
  ]);

  const handleClosePositionBlackboardModal = useCallback(() => {
    setIsOpenSelectBlackboardPosition.off();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenPositionBlackboardModal = useCallback(() => {
    setIsOpenSelectBlackboardPosition.on();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeItemData = useCallback(
    (key: keyof DocumentSubItemDTO) => async (e: any) => {
      if (!documentItemSelected?.id || !subItem?.id || isDisabled) {
        return;
      }

      const documentItem = structuredClone(documentItemSelected);
      const now = new Date();

      if (key === "isShowBlackboard") {
        const requestId = uuid();
        const isShowBlackboard = e?.target?.checked || false;
        updateElementInArray({
          array: documentItem?.subItems || [],
          keyIndex: SubItemKey.ID,
          element: {
            ...subItem,
            isShowBlackboard,
            updatedAt: now,
          },
        });

        clearTimeout(timeoutChangeItemData);
        timeoutChangeItemData = setTimeout(async () => {
          const { data } = await documentItemApi.updateSubItem({
            id: subItem.id,
            itemId: subItem.itemId,
            isShowBlackboard,
            mapTitleKey: { isShowBlackboard: "isShowBlackboard" } as any,
            requestId,
            updatedAt: now,
          } as DocumentSubItemDTO);

          socket.updateSubItem(documentItem, subItem, {
            isShowBlackboard,
            updatedAt: data.updatedAt,
          });

          insertItemLog?.({
            field: "isShowBlackboard" as any,
            value: isShowBlackboard,
            requestId,
          });
        }, 300);
      }
      dispatch(updateDocumentItem(documentItem));
    },
    [documentItemSelected, subItem, isDisabled, insertItemLog, dispatch, socket]
  );

  const handleSaveBlackboardData = useCallback(async () => {
    const blackboardChanged = tempBlackboardChangedRef.current;
    setIsFocusBlackboard.off();
    if (
      !dataBlackboard?.id ||
      isDisabled ||
      !subItem?.id ||
      !blackboardChanged?.id
    ) {
      return;
    }

    const prevBlackboardChanged =
      prevDataBlackboardRef.current || dataBlackboard;

    const { dataChanges } = checkDiffTwoObject({
      object: blackboardChanged,
      base: prevBlackboardChanged,
    });

    if (!Object.keys(dataChanges).length) {
      return;
    }

    const bodyUpdate = Object.keys(dataChanges).reduce((acc: any, key) => {
      acc[key] = blackboardChanged?.[key as keyof typeof blackboardChanged];

      return acc;
    }, {});

    let newDataBlackboard: Blackboard = {
      ...dataBlackboard,
      ...blackboardChanged,
      ...bodyUpdate,
      subItemId: subItem.id,
    };

    // temp data's saved
    tempBlackboardChangedRef.current = newDataBlackboard;
    prevDataBlackboardRef.current = newDataBlackboard;

    const requestId = uuid();
    let thumbnail = "";
    const image = await snapShotBlackboard({
      elementId: BLACKBOARD_CONTAINER_ID,
      isMobile,
    });

    if (image) {
      const file = base64ToFile(image, "thumbnail.jpeg");
      thumbnail = await uploadFileToS3(file, file.name, S3_PATH.DocumentLog, {
        requestId,
      });
    }

    const payload = {
      id: newDataBlackboard.id,
      ...bodyUpdate,
      thumbnail,
      blackboardTemplateId: blackboardTemplate?.id,
      subItemId: subItem.id,
      requestId,
    };
    const result = await blackboardApi.updateBlackboard(payload);

    newDataBlackboard = { ...newDataBlackboard, ...result.data };
    tempBlackboardChangedRef.current = newDataBlackboard;
    prevDataBlackboardRef.current = newDataBlackboard;

    setDataBlackboardState(newDataBlackboard);
    dispatch(setDataBlackboard(newDataBlackboard));

    insertItemLog?.({
      field: "blackboard" as any,
      blackboardThumbnail: thumbnail,
      blackboardTemplateId: blackboardTemplate?.id,
      value: newDataBlackboard,
      requestId,
    });

    socket.updateBlackboard(documentItemSelected?.level!, result.data.id!, {
      ...payload,
      updatedAt: result.data.updatedAt,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blackboardTemplate?.id,
    isMobile,
    dataBlackboard,
    isDisabled,
    subItem?.id,
    dispatch,
    insertItemLog,
  ]);

  const handleChangeBlackboardData = useCallback(
    async (data: Blackboard) => {
      if (!dataBlackboard?.id || isDisabled || !subItem?.id) {
        return;
      }

      const body: Partial<Blackboard> = {
        ...data,
        updatedAt: new Date(),
      };
      const newDataBlackboard: Blackboard = {
        ...dataBlackboard,
        ...tempBlackboardChangedRef.current,
        ...body,
        subItemId: subItem.id,
      };
      tempBlackboardChangedRef.current = newDataBlackboard;
    },
    [dataBlackboard, isDisabled, subItem?.id]
  );

  const handleUpdateDataBlackboardState = useCallback(
    (data: Partial<Blackboard>) => {
      if (isDisabled || !subItem?.id) return;
      if (dataBlackboard) {
        const now = new Date();

        setDataBlackboardState((prev) => ({
          ...prev!,
          ...data,
          subItemId: subItem?.id,
          updatedAt: data.updatedAt ?? now,
        }));
      }
    },
    [dataBlackboard, subItem, isDisabled]
  );

  return {
    isOpenSelectBlackboardPosition,
    dataBlackboard,
    isShowBlackboard,
    blackboardTemplate,
    loadingGetBlackboardData,
    blackboardPosition,
    isFocusBlackboard,

    setIsFocusBlackboard,
    handleChangeItemData,
    handleOpenPositionBlackboardModal,
    handleClosePositionBlackboardModal,
    handleChangeBlackboardData,
    handleUpdateDataBlackboardState,
    handleSaveBlackboardData,
  };
};

export default useBlackboard;
