import {
  authApi,
  blackboardTemplateApi,
  documentCategoryApi,
  documentGroupApi,
  documentItemApi,
  documentTaskApi,
  documentTemplateApi,
  familyApi,
  forgeApi,
  partnerCompanyApi,
  projectApi,
  bimFileApi,
  taskApi,
  taskSheetTemplateApi,
  taskTypeApi,
  userApi,
  userSettingApi,
} from "apiClient/v2";
import {
  COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME,
  COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI,
  PROJECT_RESOURCE,
  TINY_CLOUD_RESOURCE,
} from "constants/cache";
import { MODERN_SCREENSHOT_WORKER_URL } from "constants/downloadPDF";
import { API_GET_CACHE, PROJECT_CACHE_INFO } from "constants/serviceWorker";
import { Level } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { setSyncDataOption } from "redux/appSlice";
import { checkCacheProject, setCachingProject } from "redux/projectSlice";
import store from "redux/store";
import { doGetMCEKey, doRefreshToken } from "../authen";
import { getBimFileInfo } from "../bim";
import { decryptedMCEKey, sleep, uuid } from "../common";
import { dispatchEvent } from "../event";
import { DEFAULT_NUM_RETRY, retryFetch } from "../fetch";
import { getForgeToken } from "../forge";
import { getDataFamilyInstance, getDataSpace, getAECData } from "../forge/data";
import { getCacheProjectById, getIndexedDb } from "../indexedDb";
import { logError } from "../logs";
import { getListUser } from "../user";
import { getUrlsOfModel } from "./common";

export const cacheDefaultData = async () => {
  const refreshToken = await doRefreshToken();
  if (refreshToken) {
    await userApi.handleGetProfile(true);
  }
  await Promise.all([
    forgeApi.getProjects(),
    projectApi.getProjectList(),
    bimFileApi.getProjectList(),
  ]);
};

export const _currentCachingProgress: {
  [key: string]: {
    total: number;
    cached: number;
    isPause?: boolean | undefined;
  };
} = {};

export const saveProgressToStorage = async ({
  progress,
  total,
  cached,
  lastCacheTime,
  projectId,
  isForceUpdate = false,
  onlyUpdate = false,
}: {
  projectId: string;
  progress: number;
  total?: number;
  cached?: number;
  lastCacheTime?: Date;
  isForceUpdate?: boolean;
  onlyUpdate?: boolean;
}) => {
  if (!projectId) {
    return;
  }

  const cacheProject = await getCacheProjectById(projectId);
  if (onlyUpdate && !cacheProject && !isForceUpdate) {
    return;
  }
  // only allow update when pause = false except case forge update
  if (!isForceUpdate && cacheProject?.isPause) {
    return;
  }
  const newCacheProject = {
    total: typeof total === "undefined" ? cacheProject?.total : total,
    cached: typeof cached === "undefined" ? cacheProject?.cached : cached,
    progress: progress || 0,
    lastCacheTime,
    id: projectId,
    isPause: false,
  };
  const indexDb = await getIndexedDb();
  await indexDb?.put(projectId, newCacheProject, PROJECT_CACHE_INFO);
};

export const updateMapSyncDataTimeWhenCacheDone = (
  projectId: string,
  defaultBimPathId: string | undefined
) => {
  const syncDataOption = structuredClone(store.getState().app.syncDataOption);
  const mapSyncDataTimeByProject =
    syncDataOption?.mapSyncDataTimeByProject || {};
  const mapDefaultBimPathIdByProject =
    syncDataOption.mapDefaultBimPathIdByProject || {};
  mapSyncDataTimeByProject[projectId] = new Date();
  mapDefaultBimPathIdByProject[projectId] = defaultBimPathId || "";
  store.dispatch(
    setSyncDataOption({
      mapSyncDataTimeByProject,
      mapDefaultBimPathIdByProject,
    })
  );
};

const progressCallback = async (
  projectId: string,
  defaultBimPathId: string | undefined
) => {
  if (_currentCachingProgress[projectId]?.isPause) {
    return;
  }
  _currentCachingProgress[projectId].cached += 1;
  const cachingData = _currentCachingProgress[projectId];
  const progress = Math.floor((cachingData.cached * 100) / cachingData.total);

  await saveProgressToStorage({
    progress,
    total: cachingData.total,
    cached: cachingData.cached,
    lastCacheTime: new Date(),
    projectId,
    onlyUpdate: true,
  });

  if (progress >= 100) {
    store.dispatch(checkCacheProject());
    store.dispatch(setCachingProject(undefined));
    if (projectId) {
      updateMapSyncDataTimeWhenCacheDone(projectId, defaultBimPathId);
    }
  }

  dispatchEvent({
    type: `cache-${projectId}`,
    payload: _currentCachingProgress[projectId],
  });
};

export const cacheProjectData = async (
  project: DataProjectModel,
  cachedUrls: string[]
) => {
  const urn = project.defaultBimPathId?.split("/").pop();
  const { bimFileId, version } = getBimFileInfo(urn || "");
  const cachedProject = await getCacheProjectById(project.id);

  if (!bimFileId || !version || cachedProject?.isPause) {
    return;
  }

  const levelData = (project.levelData || {}) as {
    [key: string]: Level;
  };
  const levels = Object.values(levelData).filter((level) => !!level.guid);
  const isStopCache = cachedProject?.isPause;
  const indexedDb = await getIndexedDb();
  const updateProgress = async (key: string) => {
    if (isStopCache) {
      return;
    }

    const keys: string[] = (await indexedDb.getProjectDataCacheProgress())
      .filter((item: any) => item.projectId === project.id)
      .map((item: any) => item.key);
    if (keys.includes(key)) {
      return;
    }

    const result = await indexedDb?.putProjectProgress(uuid(), {
      projectId: project.id,
      key,
    });
    if (result) {
      progressCallback(project.id, project.defaultBimPathId);
    }

    return;
  };

  if (!cachedUrls.includes(PROJECT_RESOURCE.tinyCloud)) {
    await doGetMCEKey();
    const mceKey = decryptedMCEKey();
    const requests = TINY_CLOUD_RESOURCE.map((resouce) =>
      retryFetch(`https://cdn.tiny.cloud/1/${mceKey}${resouce}`)
    );

    await Promise.all(requests);
    await updateProgress(PROJECT_RESOURCE.tinyCloud);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.refreshToken)) {
    await doRefreshToken();
    await updateProgress(PROJECT_RESOURCE.refreshToken);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.currentUser)) {
    const res = await authApi.getCurrentUser(true);
    if (!!res) {
      await updateProgress(PROJECT_RESOURCE.currentUser);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.forgeData)) {
    const levelData = project.levelData || {};
    const results = await Promise.all(
      Object.keys(levelData).map((level) => {
        return getDataFamilyInstance({
          bimFileId,
          version,
          level,
          shouldCache: true,
        });
      })
    );
    if (results.every((item) => !!item)) {
      await updateProgress(PROJECT_RESOURCE.forgeData);
    } else {
      throw new Error("Download family failed");
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.spacesData)) {
    const results = await Promise.all(
      Object.keys(levelData).map((level) => {
        return getDataSpace({
          bimFileId,
          version,
          level,
          shouldCache: true,
        });
      })
    );
    if (results.every((item) => !!item)) {
      await updateProgress(PROJECT_RESOURCE.spacesData);
    } else {
      throw new Error("Download space failed");
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.aecData)) {
    const res = await getAECData(`${bimFileId}?version=${version}`, true);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.aecData);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.modernScreenshot)) {
    const res = await retryFetch(
      `${process.env.PUBLIC_URL}${MODERN_SCREENSHOT_WORKER_URL}`
    );
    if (res) {
      await updateProgress(PROJECT_RESOURCE.modernScreenshot);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.versions)) {
    const { data: res } = await forgeApi.getVersionsByBimFileId({
      projectId: project?.projectId,
      bimFileId: bimFileId.replace("fs.file:vf.", "dm.lineage:"),
      shouldCache: true,
    });

    if (res) {
      await updateProgress(PROJECT_RESOURCE.versions);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.families)) {
    const res = await familyApi.getFamilyList(true);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.families);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.tasks)) {
    for (const level of levels) {
      await taskApi.handleGetTasks({
        bimFileId,
        shouldCache: true,
        level: level.label,
      });
    }

    await updateProgress(PROJECT_RESOURCE.tasks);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.taskTypes)) {
    const res = await taskTypeApi.handleGetTaskTypes({ shouldCache: true });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.taskTypes);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentCategories)) {
    for (const level of levels) {
      await documentCategoryApi.handleGetCategoryList({
        bimFileId,
        level: level.label,
        shouldCache: true,
      });
    }

    await updateProgress(PROJECT_RESOURCE.documentCategories);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentItems)) {
    for (const level of levels) {
      await documentItemApi.handleGetItemList({
        bimFileId,
        level: level.label,
        shouldCache: true,
      });
    }
    await updateProgress(PROJECT_RESOURCE.documentItems);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.blackboardTemplate)) {
    const res = await blackboardTemplateApi.getTemplateList(true);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.blackboardTemplate);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.listUser)) {
    const res = await getListUser(true);
    if (res.length) {
      await updateProgress(PROJECT_RESOURCE.listUser);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.userSetting)) {
    const res = await userSettingApi.getUserSetting(true);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.userSetting);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.project)) {
    await forgeApi.getProjects();
    await bimFileApi.getProject(project.id);
    await updateProgress(PROJECT_RESOURCE.project);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentTemplate)) {
    const res = await Promise.all([
      documentTemplateApi.handleGetTemplateList({
        bimFileId: project.id,
        isActive: true,
        shouldCache: true,
      }),
    ]);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.documentTemplate);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.taskSheetTemplates)) {
    const res = await taskSheetTemplateApi.getTemplateList({
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.taskSheetTemplates);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.projectBimFileDetail)) {
    const { data: res } = await bimFileApi.getProject(project.id);

    if (res) {
      await updateProgress(PROJECT_RESOURCE.projectBimFileDetail);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.projectDetail)) {
    const { data: res } = await projectApi.getProject(project.projectId, true);

    if (res) {
      await updateProgress(PROJECT_RESOURCE.projectDetail);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentTasks)) {
    const res = await documentTaskApi.getDocumentTasksByBimFileId({
      bimFileId,
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.documentTasks);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.partnerCompanies)) {
    const res = await partnerCompanyApi.getPartnerList({
      bimFileId: project.id,
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.partnerCompanies);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentGroup)) {
    const res = await documentGroupApi.getGroupsList({
      bimFileId: project.id,
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.documentGroup);
    }
  }

  return true;
};

const addRequestToCache = async (url: string, res: Response) => {
  try {
    const cache = await caches.open(API_GET_CACHE);
    const cacheKey = new Request(url);
    await cache.put(cacheKey, res);
  } catch (err) {
    logError(err);
  }
};

export const cacheCommonModelData = async (
  project: DataProjectModel,
  cachedUrls: string[],
  isSafari: boolean
) => {
  const commonUrls = isSafari
    ? COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI
    : COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME;
  // cache common autodesk resource
  await Promise.all(
    commonUrls
      .filter((url) => !cachedUrls.includes(url))
      .map((url) =>
        retryFetch(url, {}, DEFAULT_NUM_RETRY, project.id).then((res) => {
          if (res && res?.status !== 500) {
            return Promise.all([
              addRequestToCache(url, res),
              progressCallback(project.id, project.defaultBimPathId),
            ]);
          }
        })
      )
  );
};

export const cacheModelData = async (
  project: DataProjectModel,
  cachedUrls: string[]
) => {
  // cache model
  const credentials = await getForgeToken();
  const modelUrls = await getUrlsOfModel(project);
  const urlsToCache = modelUrls.filter((url) => !cachedUrls.includes(url));
  let requests: Promise<any>[] = [];
  const cachedProject = await getCacheProjectById(project?.id);
  const optionRequests = {
    headers: { Authorization: `Bearer ${credentials.accessToken}` },
  };
  for (let i = 0; i < urlsToCache.length; i++) {
    const url = urlsToCache[i];
    requests.push(
      retryFetch(`${url}`, optionRequests, DEFAULT_NUM_RETRY, project.id).then(
        (res) => {
          if (res?.ok) {
            progressCallback(project.id, project.defaultBimPathId);
            addRequestToCache(url, res);
          }
        }
      )
    );

    if (cachedProject?.isPause) {
      break;
    }

    if (requests.length >= 10) {
      await Promise.all(requests);
      await sleep(50);
      requests = [];
    }
  }
};
