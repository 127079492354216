import { s3Api } from "apiClient/v2";
import { S3PresignedUrlReq } from "apiClient/v2/s3Api";
import imageCompression from "browser-image-compression";
import { message } from "components/base";
import { AUDIO_FILE_EXTENSIONS } from "constants/audio";
import { DOC_FILE_EXTENSIONS } from "constants/document";
import {
  ATTACH_UPLOAD_ERROR_MESSAGE,
  defaultImagePath,
  FileType,
  FILE_SIZE_ERROR_MESSAGE,
  IMAGE_UPLOAD_ERROR_MESSAGE,
  LIMIT_FILE_SIZE,
  SUPPORTED_FILE_TYPES_ATTACH,
  SUPPORTED_FILE_TYPES_IMAGE,
  TypeOfFile,
  UPLOAD_MULTIPLE_VIDEOS_MESSAGE,
  VIDEO_FILE_EXTENSIONS,
} from "constants/file";
import { MAX_SIZE_IMAGE } from "constants/forge";
import { IMAGE_FILE_EXTENSIONS } from "constants/photo";
import { S3_PATH } from "constants/s3";
import {
  Operation,
  STATIC_FILES_CACHE,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import ExifReader from "exifreader";
import ImageBlobReduce from "image-blob-reduce";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { configImageCompress } from "pages/forge-viewer";
import Pica from "pica";
import { formatKey, getNetworkStatus, sleep, uuid } from "./common";
import { convertStringToDate, formatDate } from "./date";
import { fetchRequest } from "./fetch";
import { checkIsImageExists } from "./image";
import { getIndexedDb } from "./indexedDb";
import { logError } from "./logs";

const MAX_RETRY_UPLOAD_FILE_TO_S3 = 10;

export const regexBase64 = /(data:image\/[^;]+;base64.*?)/i;

export const imageUrlToBase64 = async (url: string, shouldCache = false) => {
  if (regexBase64.test(url)) {
    return url;
  }
  const response = (await fetchRequest(url, {}, shouldCache).catch(
    () => ""
  )) as any;
  if (!response) {
    return url;
  }
  const blob = await response.blob();

  return new Promise((onSuccess, onError) => {
    try {
      const reader = new FileReader();
      reader.onload = function () {
        onSuccess(this.result);
      };
      reader.readAsDataURL(blob);
    } catch (e) {
      onError(e);
    }
  });
};

export const fileToBase64 = (file: Blob | File) => {
  return new Promise<string>((resolve) => {
    const reader: FileReader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      resolve(e.target?.result as string);
    };
    reader.readAsDataURL(file);
  });
};

export const base64ToFile = (src: string, filename: string) => {
  const arr = src.split(",");
  const mime = arr[0].match(/:([^;]*);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const base64toBlob = (src: string) => {
  const arr = src.split(",");
  const mime = arr[0].match(/:([^;]*);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const blob = new Blob([u8arr], { type: mime });

  return blob;
};

export const uploadFileToS3 = async (
  file: Blob | File,
  fileName: string,
  filePath: string = "",
  options?: {
    keepOriginName?: boolean;
    retryCount?: number;
    isRetry?: boolean;
    requestId?: string;
  }
): Promise<string> => {
  return new Promise(async (resolve) => {
    const split = fileName.split(".");
    const extension = split.pop();
    const retryCount = options?.retryCount ?? MAX_RETRY_UPLOAD_FILE_TO_S3;
    const isRetry = options?.isRetry;

    if (!filePath) {
      if (file?.type?.includes("image")) {
        filePath = S3_PATH.Photo;
      } else if (isAudio(fileName)) {
        filePath = S3_PATH.Audio;
      } else if (isDoc(fileName)) {
        filePath = S3_PATH.Document;
      }
    }

    const savingName = options?.keepOriginName
      ? fileName
      : `${uuid()}.${extension}`;

    let finalUrl = `${process.env.REACT_APP_S3_URL}/${filePath}/${savingName}`;
    if (!filePath) {
      finalUrl = `${process.env.REACT_APP_S3_URL}/${savingName}`;
    }
    const isOnline = getNetworkStatus();

    const handleUploadFileWhenOffline = async () => {
      // handle upload file when offline
      const cache = await caches.open(STATIC_FILES_CACHE);
      const request = new Request(finalUrl, {
        method: "GET",
      });

      await cache.put(
        request,
        new Response(new Blob([file], { type: file.type }), {
          headers: { "Content-Type": file.type },
        })
      );

      await (
        await getIndexedDb()
      )?.putFile(`${filePath}/${savingName}`, {
        status: UpdateToOnlineStatus.Fail,
        requestTime: Date.now(),
        requestId: options?.requestId,
        operation: Operation.Post,
        data: {
          filePath: filePath,
          fileName: savingName,
          s3Method: "put",
          size: file.size,
        },
      } as iCachedItem);
    };

    if (isOnline) {
      let presignedUrl = await s3Api
        .presignedUrl(
          {
            filePath,
            fileName: savingName,
            s3Method: "put",
          },
          {
            headers: {
              isIgnoreShowMessageError: !isRetry,
            },
          }
        )
        .then((res) => res?.data)
        .catch(() => "");

      if (presignedUrl) {
        await s3Api
          .uploadToS3({
            presignedUrl,
            file,
            isIgnoreShowMessageError: !isRetry,
          })
          .catch(() => {
            return;
          });
      }

      if (isRetry) {
        await sleep(300 * (MAX_RETRY_UPLOAD_FILE_TO_S3 - retryCount));
      }
      presignedUrl = await getPreSignUrl(finalUrl, "", !isRetry).catch(
        () => ""
      );
      const isImage = file.type.includes("image");
      let fileLoaded: boolean = !!presignedUrl;
      if (isImage) {
        fetch(presignedUrl).catch(() => {
          return;
        });
        fileLoaded = await checkIsImageExists(presignedUrl).catch(() => false);
      }

      if (!isImage && presignedUrl) {
        fileLoaded = await fetch(presignedUrl)
          .then((res) => {
            return res.ok;
          })
          .catch(() => false);
      }

      if (!fileLoaded && !isOnline) {
        await handleUploadFileWhenOffline();

        return resolve(finalUrl);
      }

      if (fileLoaded) {
        return resolve(finalUrl);
      } else if (retryCount && isRetry) {
        await s3Api.deleteFiles([`${filePath}/${savingName}`]);
        const finalUrlWhenRetry = await uploadFileToS3(
          file,
          fileName,
          filePath,
          {
            retryCount: retryCount - 1,
            isRetry: true,
          }
        );

        return resolve(finalUrlWhenRetry);
      }

      if (isRetry && !retryCount) {
        message.error("An error occurs when uploading image");
      }

      if (!isOnline) {
        return resolve(finalUrl);
      }

      return resolve(null as any);
    } else {
      await handleUploadFileWhenOffline();

      return resolve(finalUrl);
    }
  }).catch(() => {
    message.warning("Image upload has failed");

    return "";
  }) as any;
};

export const getPreSignUrl = async (
  filePath: string,
  fileName: string,
  isIgnoreShowMessageError = false,
  shouldCache = false
) => {
  const isBase64 = filePath.includes("data:image/");
  if (isBase64) {
    return filePath;
  }

  return s3Api
    .presignedUrl(
      { filePath, fileName, s3Method: "get" },
      {
        params: {
          shouldCache,
        },
        headers: {
          isIgnoreShowMessageError,
        },
      }
    )
    .then((res) => {
      if (!res?.data && !getNetworkStatus()) return filePath;

      return res?.data;
    });
};

export const getPreSignUrls = async (
  files: S3PresignedUrlReq[],
  isIgnoreShowMessageError = false,
  shouldCache = false
) => {
  const isOnline = getNetworkStatus();

  if (!isOnline) {
    return Promise.all(
      files.map((file) => getPreSignUrl(file.filePath, file.fileName))
    ).then((response) => {
      return response.map((data, index) => ({
        url: files[index].filePath,
        presigned: data,
      }));
    });
  }

  const base64Files: { presigned: string; url: string }[] = [];
  const s3Files: S3PresignedUrlReq[] = [];
  files.forEach((file) => {
    if (file.filePath.includes("data:image/")) {
      base64Files.push({ presigned: file.filePath, url: file.filePath });
    } else {
      s3Files.push(file);
    }
  });

  if (!s3Files.length) {
    return base64Files;
  }

  return s3Api
    .presignedUrls(s3Files, {
      params: {
        shouldCache,
      },
      headers: {
        isIgnoreShowMessageError,
      },
    })
    .then((res) => {
      return [...(res?.data || []), ...base64Files];
    });
};

export const removeFileS3 = async ({
  keys,
  requestId,
}: {
  keys: string[];
  requestId?: string;
}) => {
  if (!keys.length) {
    return;
  }

  const handleRemoveCache = async () => {
    if (!("caches" in window)) {
      return;
    }

    const cache = await caches.open(STATIC_FILES_CACHE);
    const cacheKeys = await cache.keys();

    const requests: Request[] = [];
    keys.forEach((key) => {
      const request = cacheKeys.find((req) => {
        const reqPathname = decodeURIComponent(
          decodeURIComponent(new URL(req.url).pathname)
        );
        const currentPathname = `/${decodeURIComponent(key)}`;

        return reqPathname === currentPathname;
      });

      request && requests.push(request);
    });

    if (requests.length) {
      await Promise.all(
        requests.map((request) =>
          cache.delete(request, {
            ignoreMethod: true,
            ignoreSearch: true,
            ignoreVary: true,
          })
        )
      );
    }
  };

  await handleRemoveCache();

  if (getNetworkStatus()) {
    const { data } = await s3Api.deleteFiles(keys);

    return data;
  } else {
    // handle delete when offline
    const indexedDb = await getIndexedDb();

    return await Promise.all(
      keys.map((key) =>
        indexedDb?.putFile(key, {
          status: UpdateToOnlineStatus.Fail,
          requestTime: Date.now(),
          operation: Operation.Delete,
          data: key,
          requestId,
        } as iCachedItem)
      )
    );
  }
};

export function formatFileSize(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024; // 1 kilo byte
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const getAbsolutePath = (href: string) => {
  const link = document.createElement("a");
  link.href = href;

  return link.href;
};

export const downloadFile = async (path: string) => {
  const url = getAbsolutePath(path);
  const filename = url?.split("?")?.[0]?.split("/")?.pop() || "";
  await fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      a.click();
      a.remove();
    })
    .catch();
};

export const downloadObject = async (path: string) => {
  let url = getAbsolutePath(path);
  if (
    url &&
    url.includes(`${process.env.REACT_APP_S3_URL}/`) &&
    !url.includes("X-Amz-SignedHeaders=host")
  ) {
    url = await getPreSignUrl(url, "");
  }

  const fetchResponse = await fetch(url);

  return await fetchResponse.json();
};

export const isImage = (fileName?: string) => {
  if (!fileName) {
    return false;
  }
  const fileExtension = fileName.split(".").pop();

  return (
    !!fileExtension &&
    IMAGE_FILE_EXTENSIONS.includes(fileExtension.toLowerCase())
  );
};

export const isAudio = (fileName?: string) => {
  if (!fileName) {
    return false;
  }
  const fileExtension = fileName.split(".").pop();

  return (
    !!fileExtension &&
    AUDIO_FILE_EXTENSIONS.includes(fileExtension.toLowerCase())
  );
};

export const isVideo = (fileName?: string) => {
  if (!fileName) {
    return false;
  }
  const fileExtension = fileName.split(".").pop();

  return (
    !!fileExtension &&
    VIDEO_FILE_EXTENSIONS.includes(fileExtension.toLowerCase())
  );
};

export const isDoc = (fileName?: string) => {
  if (!fileName) {
    return false;
  }
  const fileExtension = fileName.split(".").pop();

  return (
    !!fileExtension && DOC_FILE_EXTENSIONS.includes(fileExtension.toLowerCase())
  );
};

/**
 * Read text from URL location
 */
export const getTextFromUrl = async (url: string) => {
  if (
    url?.includes(`${process.env.REACT_APP_S3_URL}/`) &&
    !url?.includes("X-Amz-SignedHeaders=host")
  ) {
    url = await getPreSignUrl(url, "");
  }

  return fetch(url)
    .then((r) => {
      if (r.status === 200) return r.text();

      return "";
    })
    .catch();
};

export const getInfoFile = (
  dataURL: string
): Promise<{ height: number; width: number }> =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

/**
 * It converts a csv file to an array of object
 * @param {File} file - File - The file object that you want to convert
 * @returns A promise that resolves to an array of objects.
 */
export const csvToObjects = (file: File) => {
  const reader = new FileReader();
  const rows: Object[] = [];

  if (file.type !== "text/csv") {
    return [];
  }

  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      const contents = e?.target?.result as string;
      const text = contents.split(/\r\n|\n/);
      const [first, ...lines] = text;
      const headers = first.split(",");

      lines.forEach((line) => {
        const values = line.split(",");
        const row = Object.fromEntries(
          headers.map((header, i) => [header, values[i]])
        );
        rows.push(row);
      });

      resolve(rows);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsText(file);
  });
};

/**
 * It normalizes csv data
 * @param {Object[]} data - Object[] - the data that we want to normalize
 * @returns An array of objects.
 */
export const normalizeCsvData = (data: Object[]) => {
  const normalizedData: Object[] = [];

  data?.forEach((item) => {
    const isValidItem = Object.values(item).some((value) => !!value);

    if (isValidItem) {
      const normalizedItem = {};

      Object.entries(item).forEach(([key, value]) => {
        if (key) {
          Object.assign(normalizedItem, {
            [formatKey(key)]: value,
          });
        }
      });

      normalizedData.push(normalizedItem);
    }
  });

  return normalizedData;
};

export const verifyFile = async (
  fileList: FileList,
  type = TypeOfFile.ATTACH
) => {
  const arrMIME = await getMIME(fileList);

  const isTypeImage = arrMIME.every((mime) =>
    SUPPORTED_FILE_TYPES_IMAGE.includes(mime)
  );
  if (type === TypeOfFile.IMAGE && !isTypeImage) {
    return IMAGE_UPLOAD_ERROR_MESSAGE;
  }

  const isMultipleVideo =
    arrMIME.filter((mime) => mime === "video/mp4").length > 1;
  if (isMultipleVideo) {
    return UPLOAD_MULTIPLE_VIDEOS_MESSAGE;
  }

  const isTypeAttach = arrMIME.every((mime) =>
    SUPPORTED_FILE_TYPES_ATTACH.includes(mime)
  );
  if (type === TypeOfFile.ATTACH && !isTypeAttach) {
    return ATTACH_UPLOAD_ERROR_MESSAGE;
  }

  const isValidFileSize = Array.from(fileList).every(
    (file) => file.size < LIMIT_FILE_SIZE
  );
  if (!isValidFileSize) {
    return FILE_SIZE_ERROR_MESSAGE;
  }

  return;
};

export const getMIME = async (files: FileList) => {
  const filePromises = Array.from(files).map((file) => {
    return new Promise<string>((resolve) => {
      const blob = file;
      const fileReader = new FileReader();

      fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
        let header = "",
          type = "";
        const arr = new Uint8Array((e.target as any).result).subarray(0, 4);
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        switch (header) {
          case "89504e47":
            type = FileType.IMAGE_PNG;
            break;

          case "47494638":
            type = FileType.IMAGE_GIF;
            break;

          case "ffd8ffe0":
          case "ffd8ffe1":
          case "ffd8ffe2":
          case "ffd8ffe3":
          case "ffd8ffe8":
            type = FileType.IMAGE_JPEG;
            break;

          case "4d4d002a":
            type = FileType.IMAGE_TIFF;
            break;

          case "52494646":
          case "57454250":
            type = FileType.IMAGE_WEBP;
            break;

          case "25504446":
            type = FileType.APPLICATION_PDF;
            break;

          case "00020":
          case "66747970":
            type = FileType.VIDEO_MP4;
            break;

          default:
            type = FileType.UNKNOWN;
            break;
        }
        resolve(type);
      };
      fileReader.readAsArrayBuffer(blob);
    });
  });

  const fileInfos = await Promise.all(filePromises);

  return fileInfos;
};

export const isValidUrl = (urlString?: string) => {
  if (!urlString) return false;
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const imageFromS3 = (url?: string) => {
  if (!url || !url.length) {
    return defaultImagePath;
  }

  return `${url}?${Date.now()}`;
};

export const getFileFromUrl = async (url: string) => {
  return await fetch(url)
    .then((res) => res?.blob())
    .catch();
};

export const isValidUrlS3 = (src: string) => {
  if (!src) return false;
  if (!src.includes(`${process.env.REACT_APP_S3_URL}/`)) return true;
  if (!src.includes("X-Amz-SignedHeaders=host") && getNetworkStatus())
    return false;

  return true;
};

export const compressionImageByFile = async ({
  file,
  fileName = "",
}: {
  file: File;
  fileName?: string;
}) => {
  const pica = Pica({ features: ["js", "wasm", "cib"] });
  let newBlob = await ImageBlobReduce({ pica }).toBlob(file, {
    max: MAX_SIZE_IMAGE,
  });
  newBlob = await imageCompression(newBlob, configImageCompress);
  const newFile = new File([newBlob as Blob], fileName || file?.name || "", {
    type: file?.type,
  });

  return newFile;
};

export const getDateModifiedDateFile = (image: FileUploadInfo) => {
  if (!image) {
    return formatDate(new Date());
  }

  return formatDate(
    image.lastModifiedDateFile ? image.lastModifiedDateFile : image.uploadTime
  );
};

export const getDateTimeOriginalExif = async (file?: File) => {
  if (!file) {
    return null;
  }
  const tagsExifReader = (await ExifReader.load(file)) as any;
  const dateTimeOriginalExif = tagsExifReader["DateTimeOriginal"];
  let dateTimeOriginal = null;
  if (dateTimeOriginalExif) {
    dateTimeOriginal = convertStringToDate(dateTimeOriginalExif.description);
  }

  return dateTimeOriginal;
};

export const getImagesInfo = async ({
  images,
  currentSrcImage,
  currentIndexImage,
  hasCapturedCamera,
  originSrc,
  currentIdUser,
}: {
  images: FileModel[];
  currentIndexImage: number;
  hasCapturedCamera?: boolean;
  currentSrcImage: string;
  originSrc?: string;
  currentIdUser: string;
}) => {
  const getLastModifiedDateFile = async (
    img: FileModel,
    hasCapturedCamera?: boolean
  ) => {
    const dateTimeOriginal = await getDateTimeOriginalExif(img?.file);

    return dateTimeOriginal
      ? dateTimeOriginal
      : hasCapturedCamera
      ? null
      : img.lastModifiedDateFile;
  };

  const imagesInfoPromise = images.map(async (image, index) => {
    if (currentIndexImage === index) {
      const lastModifiedDateFile = await getLastModifiedDateFile(
        image,
        hasCapturedCamera
      );

      return {
        ...image,
        src: currentSrcImage,
        originSrc,
        userUpload: hasCapturedCamera ? currentIdUser : image.userUpload,
        uploadTime: new Date(),
        lastModifiedDateFile,
      };
    }

    return image;
  });

  return await Promise.all(imagesInfoPromise);
};

export const presignedAndDownloadFileS3 = async (
  url: string,
  shouldCache?: boolean
) => {
  try {
    if (!url || typeof url !== "string") {
      return;
    }

    if (url && regexBase64.test(decodeURIComponent(url))) {
      return url;
    }
    const presignedUrl = await getPreSignUrl(url, "", false, shouldCache);
    if (!presignedUrl) {
      return;
    }

    return await fetchRequest(presignedUrl, {}, shouldCache);
  } catch (err) {
    logError(err);
  }
};

export const checkUrlIsPdf = (url: string) => {
  if (url.startsWith("data:application/pdf;base64")) {
    return true;
  }

  return url.includes(".pdf");
};

export function detectUrlString(string?: string) {
  if (!string) {
    return false;
  }

  return /^(http|https):\/\/.*/.test(string);
}
