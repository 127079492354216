import { blackboardApi, documentItemApi } from "apiClient/v2";
import { message } from "components/base";
import { DocumentItemKey, SubItemKey } from "constants/enum";
import { FILE_SIZE_ERROR_MESSAGE, LIMIT_FILE_SIZE } from "constants/file";
import { S3_PATH } from "constants/s3";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { SizePosition } from "interfaces/models/rnd";
import { User } from "interfaces/models/user";
import {
  GetContentLog,
  OPERATION_ITEM_LOG,
  transformMapTitleKey,
} from "models/dataLog";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setDataBlackboard, updateDocumentItem } from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { uuid } from "utils/common";
import {
  base64ToFile,
  compressionImageByFile,
  fileToBase64,
  getDateTimeOriginalExif,
  uploadFileToS3,
} from "utils/file";

interface Props {
  subItem?: DocumentSubItemDTO;
  dataBlackboard?: Blackboard;
  blackboardTemplate?: BlackboardTemplate;
  documentItemSelected?: DocumentItemDTO;
  currentUser?: User | null;
  originImgSelectedTemp: FileModel;

  insertSubItemLog: (params: GetContentLog) => Promise<void>;
  onOpenPositionBlackboardModal: () => void;
  onClosePositionBlackboardModal: () => void;
  handleUpdateDataBlackboardState: (data: Partial<Blackboard>) => void;
}

const usePhoto = (props: Props) => {
  const {
    subItem,
    dataBlackboard,
    blackboardTemplate,
    documentItemSelected,
    currentUser,
    originImgSelectedTemp,
    insertSubItemLog,
    onOpenPositionBlackboardModal,
    handleUpdateDataBlackboardState,
    onClosePositionBlackboardModal,
  } = props;
  const { socket } = useForgeViewerContext();
  const [image, setImage] = useState<FileModel | undefined>();

  const dispatch = useDispatch();
  const isHasBlackboard = useMemo(
    () =>
      !!Object.keys(image?.pos || {})?.length &&
      !!dataBlackboard &&
      !!blackboardTemplate?.id,
    [image?.pos, dataBlackboard, blackboardTemplate]
  );

  const isHasBlackboardWithOutPosition = useMemo(
    () => !!dataBlackboard && !!blackboardTemplate?.id,
    [dataBlackboard, blackboardTemplate]
  );

  useEffect(() => {
    const image = structuredClone(subItem?.images);

    if (!subItem?.id || !(image as any)?.src || !image) {
      setImage(undefined);

      return;
    }
    if ("file" in image) {
      delete (image as any)?.file;
    }

    setImage({
      ...image,
      pos: subItem?.blackboardImagePosition as SizePosition,
    });
  }, [subItem?.id, subItem?.images, subItem?.blackboardImagePosition]);

  const blackboardPositionTemp = useMemo(() => {
    return [
      image?.pos?.x ?? 0,
      image?.pos?.y ?? 0,
      image?.pos?.width ?? 0,
      image?.pos?.imgWidth ?? 0,
      image?.pos?.height ?? 0,
      image?.pos?.imgHeight ?? 0,
    ];
  }, [image?.pos]);

  const handleClickSelectFile = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      target.value = "";
    },
    []
  );

  const handleChangeFile = useCallback(
    async (event: React.MouseEvent<HTMLInputElement>) => {
      if (!documentItemSelected?.id || !subItem?.id) {
        return;
      }

      const target = event.target as any;
      const file: File & {
        lastModifiedDate?: Date;
        lastModifiedDateFile?: Date;
      } = (target.files as FileList)?.[0];

      if (file.size >= LIMIT_FILE_SIZE) {
        return message.error(FILE_SIZE_ERROR_MESSAGE);
      }

      const dateTimeOriginal = await getDateTimeOriginalExif(file);
      const imgString = await fileToBase64(file);
      const newFile = await compressionImageByFile({
        file: file,
        fileName: file.name,
      });

      const fileModel: FileModel = {
        src: imgString,
        name: file.name,
        isTemp: true,
        file: newFile,
        lastModifiedDateFile: dateTimeOriginal
          ? dateTimeOriginal
          : file.lastModifiedDate
          ? file.lastModifiedDate
          : undefined,
      };

      setImage(fileModel);

      if (blackboardTemplate?.id) {
        return onOpenPositionBlackboardModal();
      }

      const now = new Date();
      const documentItem = structuredClone(documentItemSelected);
      const currentUpdatedAt = documentItem.updatedAt;
      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: SubItemKey.ID,
        element: {
          ...subItem,
          images: fileModel,
          updatedAt: now,
          isLoadingUpdateImage: true,
        } as DocumentSubItemDTO,
      });
      dispatch(updateDocumentItem(documentItem));
      const src = await uploadFileToS3(file, file?.name || "");

      const fileUploadInfo = {
        src,
        originSrc: src,
        uploadTime: new Date(),
        lastModifiedDateFile: fileModel.lastModifiedDateFile,
        userUpload: currentUser?.id || "",
      };

      const shootingTime = new Date();
      const requestId = uuid();
      const { mapLogs, mapTitleKey } = transformMapTitleKey([
        {
          field: DocumentItemKey.IMAGES as any,
          value: fileUploadInfo,
          operation: OPERATION_ITEM_LOG.CREATE,
        },
      ]);

      const [{ data: newSubItem }, { data: newBlackboard }] = await Promise.all(
        [
          documentItemApi.updateSubItem({
            id: subItem.id,
            itemId: subItem.itemId,
            images: fileUploadInfo,
            isAddMedia: true,
            requestId,
            mapTitleKey: mapTitleKey as any,
            updatedAt: now,
          } as DocumentSubItemDTO),
          blackboardApi.updateBlackboard({
            id: subItem.blackboardId,
            isInsertLog: false,
            requestId,
            subItemId: subItem.id,
            shootingTime,
            updatedAt: now,
          }),
        ]
      );

      // handle revert when save data have been failure
      if (!newSubItem?.id || !newBlackboard?.id) {
        setImage(undefined);
        updateElementInArray({
          array: documentItem?.subItems || [],
          keyIndex: SubItemKey.ID,
          element: {
            ...subItem,
            images: subItem.images,
            updatedAt: currentUpdatedAt,
            isLoadingUpdateImage: false,
          } as DocumentSubItemDTO,
        });
        dispatch(updateDocumentItem(documentItem));

        return;
      }

      socket.updateSubItem(documentItem, subItem, {
        images: fileUploadInfo,
        updatedAt: newSubItem.updatedAt,
      });
      socket.updateBlackboard(documentItem.level!, subItem.blackboardId!, {
        shootingTime,
        updatedAt: newBlackboard.updatedAt,
      });

      await Promise.all(
        Object.values(mapLogs).map((params) =>
          insertSubItemLog({ ...params, requestId })
        )
      );

      // update redux state
      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: SubItemKey.ID,
        element: {
          ...subItem,
          images: fileUploadInfo,
          updatedAt: newSubItem.updatedAt,
          isLoadingUpdateImage: false,
        } as DocumentSubItemDTO,
      });

      handleUpdateDataBlackboardState({
        shootingTime,
        updatedAt: newBlackboard.updatedAt,
      });
      dispatch(updateDocumentItem(documentItem));
      dispatch(setDataBlackboard(newBlackboard));
    },
    [
      currentUser?.id,
      documentItemSelected,
      subItem,
      blackboardTemplate?.id,
      insertSubItemLog,
      handleUpdateDataBlackboardState,
      onOpenPositionBlackboardModal,
      dispatch,
      socket,
    ]
  );

  const handleSelectBlackboardPosition = useCallback(
    async (position: SizePosition, thumbnailTemp?: string) => {
      if (!documentItemSelected?.id || !subItem?.id || !currentUser?.id) {
        return;
      }

      const blackboardImagePosition: SizePosition = position;
      const documentItem = structuredClone(documentItemSelected);
      const file = image?.file;
      const now = new Date();

      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: SubItemKey.ID,
        element: {
          ...subItem,
          isShowBlackboard: true,
          updatedAt: now,
          images: image,
          blackboardImagePosition,
          isLoadingUpdateImage: !!file,
        } as DocumentSubItemDTO,
      });

      dispatch(updateDocumentItem(documentItem));
      onClosePositionBlackboardModal();

      let fileUploadInfo: FileUploadInfo = structuredClone(
        subItem.images as any
      );
      if (file) {
        const src = await uploadFileToS3(file, file?.name || "");
        fileUploadInfo = {
          src,
          uploadTime: new Date(),
          lastModifiedDateFile: image.lastModifiedDateFile,
          userUpload: currentUser?.id || "",
          originSrc: image.originSrc ?? src,
        };
        if (originImgSelectedTemp.file) {
          const src = await uploadFileToS3(
            originImgSelectedTemp.file,
            originImgSelectedTemp?.name || ""
          );

          fileUploadInfo.originSrc = src;
        }
      }

      const shootingTime = new Date();
      const requestId = uuid();
      let thumbnail = "";
      if (thumbnailTemp) {
        const file = base64ToFile(thumbnailTemp, "thumbnail.jpeg");
        thumbnail = await uploadFileToS3(file, file.name, S3_PATH.DocumentLog, {
          requestId,
        });
      }

      const bodyBlackboardUpdate = {
        shootingTime,
        updatedAt: now,
      } as Partial<Blackboard>;
      const transformMapTitleKeyParams: GetContentLog[] = [
        {
          field: DocumentItemKey.IMAGES as any,
          value: fileUploadInfo,
          blackboardImagePosition,
          blackboardThumbnail: thumbnail,
          blackboardTemplateId: blackboardTemplate?.id,
          ...{
            ...(dataBlackboard && {
              blackboardData: { ...dataBlackboard, ...bodyBlackboardUpdate },
            }),
          },
          operation: subItem?.images
            ? OPERATION_ITEM_LOG.UPDATE
            : OPERATION_ITEM_LOG.CREATE,
        },
      ];

      const { mapTitleKey, mapLogs } = transformMapTitleKey(
        transformMapTitleKeyParams
      );

      const bodySubItemUpdate = {
        images: fileUploadInfo,
        isShowBlackboard: true,
        blackboardImagePosition,
        blackboardTemplateId: blackboardTemplate?.id,
        thumbnail,
        mapTitleKey: mapTitleKey as any,
        isEditMedia: image?.isEditMedia,
        updatedAt: now,
      } as Partial<DocumentSubItemDTO>;

      // add log blackboardData to revert
      if (transformMapTitleKeyParams?.[0]?.blackboardData) {
        bodySubItemUpdate.blackboardData =
          transformMapTitleKeyParams[0].blackboardData;
      }

      const [{ data: newSubItem }, { data: newBlackboard }] = await Promise.all(
        [
          documentItemApi.updateSubItem({
            id: subItem.id,
            itemId: subItem.itemId,
            ...bodySubItemUpdate,
            requestId,
          } as DocumentSubItemDTO),
          blackboardApi.updateBlackboard({
            id: subItem.blackboardId,
            subItemId: subItem.id,
            ...bodyBlackboardUpdate,
            isInsertLog: false,
            requestId,
          }),
        ]
      );

      // sync data by websocket
      socket.updateSubItem(documentItem, subItem, {
        images: fileUploadInfo,
        isShowBlackboard: true,
        blackboardImagePosition,
        updatedAt: newSubItem.updatedAt,
      });

      socket.updateBlackboard(documentItem.level!, subItem.blackboardId!, {
        shootingTime,
        updatedAt: newBlackboard.updatedAt,
      });

      await Promise.all(
        Object.values(mapLogs).map((params) =>
          insertSubItemLog({
            ...params,
            isEditMedia: image?.isEditMedia,
            requestId,
          })
        )
      );
      if (file) {
        updateElementInArray({
          array: documentItem?.subItems || [],
          keyIndex: SubItemKey.ID,
          element: {
            ...subItem,
            isShowBlackboard: true,
            updatedAt: newSubItem.updatedAt,
            images: fileUploadInfo,
            blackboardImagePosition,
            isLoadingUpdateImage: false,
          } as DocumentSubItemDTO,
        });
      }

      handleUpdateDataBlackboardState({ shootingTime });
      dispatch(updateDocumentItem(documentItem));
      dispatch(setDataBlackboard(newBlackboard));
    },
    [
      dataBlackboard,
      blackboardTemplate?.id,
      originImgSelectedTemp,
      documentItemSelected,
      image,
      currentUser,
      subItem,
      dispatch,
      insertSubItemLog,
      handleUpdateDataBlackboardState,
      onClosePositionBlackboardModal,
      socket,
    ]
  );

  const handleClosePositionBlackboardModal = useCallback(() => {
    onClosePositionBlackboardModal();
    setImage({
      ...subItem?.images,
      pos: (subItem?.blackboardImagePosition || {}) as SizePosition,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subItem?.images, subItem?.blackboardImagePosition]);

  return {
    isHasBlackboard,
    isHasBlackboardWithOutPosition,
    image,
    setImage,
    blackboardPositionTemp,
    handleClickSelectFile,
    handleChangeFile,
    handleSelectBlackboardPosition,
    handleClosePositionBlackboardModal,
  };
};

export default usePhoto;
