import {
  CellProperty,
  DocumentTemplateType,
  LinkedDataField,
  TemplateComponentType,
} from "constants/enum";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { getCellsByColumnId } from "utils/tableCell";

export const getSizeOfComponent = (params: {
  documentType: string | undefined;
  component: TemplateComponent;
  numberItemPerPage: number;
  limit: number;
}) => {
  const { documentType, component, numberItemPerPage = 0, limit = 0 } = params;

  const width = Number(component?.realSize?.width);
  let height = Number(component?.realSize?.height);

  const isCommon =
    component.type === TemplateComponentType.Table &&
    !!component.linkedHeaderId &&
    (documentType === DocumentTemplateType.COMMISSIONING_TABLE ||
      documentType === DocumentTemplateType.PHOTO_LEDGER);
  const isFilterPhoto = component.type === TemplateComponentType.FilterPhoto;
  const isLinkedImage = component.type === TemplateComponentType.LinkedImage;

  if (isCommon || isFilterPhoto || isLinkedImage) {
    const rowHeight = height / numberItemPerPage;
    height = rowHeight * limit;
  }

  return { width, height };
};

/**
 * @param param0
 * @returns cells need update
 */
export const resetDataForCommentCell = (params: {
  headerComponent: TemplateComponent;
  linkedTables: TemplateComponent[];
}) => {
  const { headerComponent, linkedTables } = params;
  let updatedCells: CellType[] = [];
  const commentCell = headerComponent.detail?.rows?.[0]?.cells?.find(
    (cell) => cell.cellLinkedData?.field === LinkedDataField.COMMON.COMMENT
  );
  const columnId = commentCell?.position?.idColumn;
  if (columnId) {
    updatedCells = [...linkedTables, headerComponent]
      .map((component) => {
        const cells = getCellsByColumnId({
          columnId,
          component,
        }).map(({ subTable, cellLinkedData, ...cell }) => ({
          ...cell,
          cellProperty: CellProperty.TEXT,
          value: "",
        }));

        return cells;
      })
      .flat(1);
  }

  return updatedCells;
};
