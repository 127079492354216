export const STATIC_FILES_CACHE = "neptune-static-files";
export const BUNDLE_FILES_CACHE = "neptune-bundle-files";
export const API_GET_CACHE = "neptune-api-cache";
export const SERVER_ORIGIN = (process.env.REACT_APP_API_HOST_URL || "").slice(
  0,
  (process.env.REACT_APP_API_HOST_URL || "").lastIndexOf("/api")
);

export const API_SKIP_TO_CACHE = ["https://api-js.mixpanel.com/track"];

export const POST_API_TO_CACHE = [
  "/refreshToken",
  "/refresh-token",
  "https://api-js.mixpanel.com/track",
  "cdn.tiny.cloud",
];

export const LAMBDA_QUERY_TO_CACHE = ["/s3-presigned-url", "/get-s3-file-size"];

export const PROJECT_DATA_CACHE_PROGRESS_STORE =
  "project-data-cache-progress-store";
export const NETWORK_STORE = "network-store";
export const NETWORK_STORE_KEY = "status";
export const FILE_STORE = "file-store";
export const DATA_STORE = "data-store";
export const PROJECT_CACHE_INFO = "project-cache-info";

export enum StoreName {
  "TASK_TYPES" = "task-types",
  "DOCUMENT_CATEGORIES" = "document-categories",
  "BLACKBOARDS" = "blackboards",
  "SUB_ITEMS" = "sub-items",
  "DOCUMENT_ITEMS" = "document-items",
  "TASKS" = "tasks",
  "TASK_COMMENTS" = "task-comments",
  "DATA_LOGS" = "data-logs",
  "DOCUMENTS" = "documents",
  "DOCUMENT_KEYNOTES" = "document-keynotes",
  "DOCUMENT_TASKS" = "document-tasks",
  "USER_SETTINGS" = "user-settings",
  "DOCUMENT_GROUP" = "document-groups",
  "BIM_FILES" = "bim-files",
  "DOCUMENT_TEMPLATES" = "document-templates",
  "FAMILIES" = "families",
  "TASK_SHEET_TEMPLATE" = "task-sheet-templates",
}

export const AVAILABLE_STORES = [
  StoreName.TASK_TYPES,
  StoreName.DOCUMENT_CATEGORIES,
  StoreName.BLACKBOARDS,
  StoreName.DOCUMENT_ITEMS,
  StoreName.SUB_ITEMS,
  StoreName.TASKS,
  StoreName.TASK_COMMENTS,
  StoreName.DATA_LOGS,
  StoreName.DOCUMENTS,
  StoreName.DOCUMENT_KEYNOTES,
  StoreName.DOCUMENT_TASKS,
  StoreName.USER_SETTINGS,
  StoreName.DOCUMENT_GROUP,
  StoreName.BIM_FILES,
  StoreName.DOCUMENT_TEMPLATES,
  StoreName.FAMILIES,
];
export const DOCUMENT_CATEGORIES = "document-categories";

export enum Operation {
  Post, // POST
  Delete,
  Patch, // PUT
}

export enum UpdateToOnlineStatus {
  Fail,
  Success,
}
