import {
  COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME,
  COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI,
  PROJECT_RESOURCE,
} from "constants/cache";

import { DataProjectModel } from "interfaces/models/dataProjectModel";

import { getIndexedDb } from "../indexedDb";
import { getUrlsOfModel } from "./common";

export const getCachedUrls = async (
  projectId: string,
  urlsOfModel: string[]
) => {
  const cachedModelData: string[] = [];
  const cacheNames = await caches.keys();
  let allUrlCached: string[] = [];
  for (let i = 0; i < cacheNames.length; i++) {
    const cache = await caches.open(cacheNames[i]);
    allUrlCached = allUrlCached.concat(
      (await cache.keys()).map((item) => item.url)
    );
  }

  for (let i = 0; i < urlsOfModel.length; i++) {
    const url = urlsOfModel[i];
    if (allUrlCached.includes(url)) {
      cachedModelData.push(url);
    }
  }

  const indexedDb = await getIndexedDb();
  const cachedProjectData = (await indexedDb.getProjectDataCacheProgress())
    .filter((item: any) => item.projectId === projectId)
    .map((item: any) => item.key);

  return { cachedModelData, cachedProjectData };
};

export const getCacheProgress = async (
  project: DataProjectModel,
  isSafari: boolean
) => {
  const commonUrls = isSafari
    ? COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI
    : COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME;
  const modelData = [...commonUrls, ...(await getUrlsOfModel(project))];
  const projectData = [...Object.values(PROJECT_RESOURCE)];
  const totalLength = modelData.length + projectData.length;

  const { cachedModelData, cachedProjectData } = await getCachedUrls(
    project.id,
    modelData
  );
  const cachedLength = cachedModelData.length + cachedProjectData.length;

  return { cachedLength, totalLength, cachedModelData, cachedProjectData };
};
