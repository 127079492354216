import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Text,
  useBoolean,
  UseModalProps,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import {
  MIN_BLACKBOARD_HEIGHT,
  MIN_BLACKBOARD_WIDTH,
} from "components/ui/BlackboardImage";
import BlackboardTemplateImage from "components/ui/BlackboardTemplateImage";
import { ContentType, ORIENTATION_TYPE } from "constants/enum";
import { WEBCAM_CONTAINER_CLASS } from "constants/styleProps";
import { useBlackboardResize } from "hooks/useBlackboardResize";
import { useResize } from "hooks/useResize";
import { Blackboard } from "interfaces/models/blackboard";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";
import { SizePosition } from "interfaces/models/rnd";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDeviceSelectors } from "react-device-detect";
import { Rnd } from "react-rnd";
import Webcam from "react-webcam";
import { getOrientationType } from "utils/common";
import { base64ToFile } from "utils/file";
import { logDev } from "utils/logs";

interface Props extends UseModalProps {
  blackBoard?: Blackboard | null;
  isCreateTask?: boolean;
  blackboardTemplateProps?: iBlackboardTemplateProps;

  onCapture: (file: File, pos: SizePosition, blackBoard?: Blackboard) => void;
  onCameraError?: () => void;
}

const DEFAULT_RATIO_ZOOM = 1 / 20;
const DEFAULT_ZOOM = 1;
const MIN_SIZE_BLACKBOARD_DISPLAY = {
  width: MIN_BLACKBOARD_WIDTH * 2,
  height: MIN_BLACKBOARD_HEIGHT * 2,
};

function CameraModal({
  isOpen,
  blackBoard,
  isCreateTask,
  blackboardTemplateProps,
  onClose,
  onCapture,
  onCameraError,
}: Props) {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const webcamRef = useRef<Webcam>(null);
  const mediaStreamRef = useRef<MediaStream>();
  const [scale, setScale] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const { width, height } = useResize();
  const [containerSize, setContainerSize] = useState<{
    width: number;
    height: number;
  }>({ width, height });

  const [enableGrid, setEnableGrid] = useBoolean();
  const [isLoadedMedia, setIsLoadedMedia] = useBoolean(false);
  const [isLoadedCamera, setIsLoadedCamera] = useBoolean(false);
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const deviceRef = useRef<MediaStreamTrack | MediaDeviceInfo>();

  const {
    pos,
    rndSize,
    bbRef,
    enableResizing,
    onResizeStop,
    onDragStop,
    onResize,
  } = useBlackboardResize({
    isOpen,
    parentHeight: containerSize?.height || 0,
    parentWidth: containerSize?.width || 0,
  });

  const handleMediaDevices = (
    mediaDevice: MediaStreamTrack | MediaDeviceInfo
  ) => {
    deviceRef.current = mediaDevice;
    setIsLoadedMedia.on();
  };

  const handleClose = useCallback(() => {
    const stream = mediaStreamRef.current;

    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
        stream.removeTrack(track);
        track = null as any;
      });
    }
    onClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initContainerSize = (stream: MediaStream) => {
    const { width: cameraWidth, height: cameraHeight } = stream
      .getTracks()[0]
      .getSettings();
    if (!cameraWidth || !cameraHeight) {
      return;
    }

    mediaStreamRef.current = stream;

    if (isMobile) {
      const orientationType = getOrientationType(window.orientation);
      let ratio = cameraWidth / cameraHeight;
      if (ratio > 1) {
        ratio = 1 / ratio;
      }
      const windowHeight = window.innerHeight;
      let scaleWidth = 0;
      let scaleHeight = 0;

      if (orientationType === ORIENTATION_TYPE.PORTRAIT) {
        scaleWidth = windowHeight;
        scaleHeight = scaleWidth * ratio;

        setContainerSize({ width: scaleHeight, height: scaleWidth });
      } else {
        scaleHeight = windowHeight;
        scaleWidth = scaleHeight / ratio;

        setContainerSize({ width: scaleWidth, height: scaleHeight });
      }

      setScale({
        width: scaleWidth,
        height: scaleHeight,
      });
    } else {
      setScale({
        width: cameraWidth || 1,
        height: cameraHeight || 1,
      });

      let containerWidth = width;
      const ratio = Number(cameraWidth) / Number(cameraHeight);
      let containerHeight = Math.floor(containerWidth / ratio);
      if (containerHeight > height) {
        containerHeight = height;
        containerWidth = Math.floor(containerHeight * ratio);
      }

      setContainerSize({
        width: containerWidth,
        height: containerHeight,
      });
    }
  };

  const initCamera = async () => {
    if (isMobile) {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment", width: 9999 },
      });
      const videoTracks = mediaStream?.getVideoTracks();
      if (videoTracks.length) {
        handleMediaDevices(videoTracks?.[0]);
      }

      initContainerSize(mediaStream);
      mediaStreamRef.current = mediaStream;
    } else {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const defaultCamera = devices.filter(
        ({ kind }) => kind === "videoinput"
      )[0];

      handleMediaDevices(defaultCamera);

      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 9999, // get max resoultion screen
          deviceId: defaultCamera.deviceId,
        },
      });

      initContainerSize(mediaStream);
    }
  };

  const getImageElementFromDataURL = (
    dataURL: string
  ): Promise<HTMLImageElement> =>
    new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = "anonymous";

      img.onload = () => {
        resolve(img);
      };
      img.onerror = (error: any) => {
        logDev("getImageElementFromDataURL error: ", error);
      };
      img.src = dataURL;
    });

  const handleCapture = useCallback(async () => {
    if (!webcamRef?.current) {
      return;
    }

    const imageSrc = webcamRef.current?.getScreenshot();

    if (!imageSrc) {
      return;
    }

    const width = webcamRef.current?.video?.offsetWidth || 0;
    const height = webcamRef.current?.video?.offsetHeight || 0;
    let imageElement = await getImageElementFromDataURL(imageSrc!);

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const canvas2dContext = canvas.getContext("2d");
    const sourceWidth = width / zoom;
    const sourceHeight = height / zoom;
    const sourceX = (width - sourceWidth) / 2;
    const sourceY = (height - sourceHeight) / 2;
    canvas2dContext!.clearRect(0, 0, width, height);
    canvas2dContext!.drawImage(
      imageElement,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      width,
      height
    );

    const canvasSrc = canvas.toDataURL("image/png");
    const fileName = `imgDraw-${Date.now()}`;
    const eFile = base64ToFile(canvasSrc ?? "", fileName);

    const imgWidth = width;
    const imgHeight = height;
    const newWidth =
      Number(bbRef.current.width || 0) < MIN_SIZE_BLACKBOARD_DISPLAY.width
        ? MIN_SIZE_BLACKBOARD_DISPLAY.width
        : bbRef.current.width;
    const newHeight =
      Number(bbRef.current.height || 0) < MIN_SIZE_BLACKBOARD_DISPLAY.height
        ? MIN_SIZE_BLACKBOARD_DISPLAY.height
        : bbRef.current.height || 0;

    const x =
      bbRef.current.x > imgWidth - newWidth
        ? imgWidth - newWidth
        : bbRef.current.x;
    const y =
      bbRef.current.y > imgHeight - newHeight
        ? imgHeight - newHeight
        : bbRef.current.y;

    onCapture(
      eFile,
      {
        ...bbRef.current,
        x,
        y,
        imgWidth,
        imgHeight,
        width: newWidth,
        height: newHeight,
      },
      blackBoard ? { ...blackBoard } : undefined
    );

    // dispose object image
    imageElement = null as any;
    setZoom(DEFAULT_ZOOM);
    handleClose();
  }, [bbRef, onCapture, blackBoard, handleClose, zoom]);

  const handleZoomInOut = (value: number) => {
    setZoom(DEFAULT_ZOOM + value * DEFAULT_RATIO_ZOOM);
  };

  const orientationRef = useRef(window.orientation);
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const orientationChange = () => {
      const orientationType = getOrientationType(window.orientation);
      const prevOrientationType = getOrientationType(orientationRef.current);

      if (orientationType === prevOrientationType) {
        return;
      }

      orientationRef.current = window.orientation;
      setIsLoadedMedia.off();
      setIsLoadedCamera.off();
      setZoom(DEFAULT_ZOOM);
      setScale({ width: 0, height: 0 });
      const stream = mediaStreamRef.current;

      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
          stream.removeTrack(track);
          track = null as any;
        });
      }

      setTimeout(() => {
        initCamera();
      }, 1500);
    };

    window.addEventListener("orientationchange", orientationChange);

    return () => {
      window.removeEventListener("orientationchange", orientationChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    initCamera();

    return () => {
      setIsLoadedMedia.off();
      setIsLoadedCamera.off();
      setZoom(DEFAULT_ZOOM);
      setEnableGrid.off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const styleWebcame = useMemo((): CSSProperties => {
    if (isMobile) {
      return {
        transform: `scale(${zoom})`,
        margin: "0 auto",
      };
    }

    return {
      width: containerSize.width,
      height: containerSize.height,
      position: "absolute",
      objectFit: "cover",
      top: "50%",
      left: "50%",
      transform: `translate(-50%, -50%) scale(${zoom})`,
    };
  }, [zoom, isMobile, containerSize]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        p={0}
        width={`${width}px`}
        maxWidth={`${width}px`}
        height={`${height}px`}
        position="relative"
        my="auto"
        backgroundColor="#0f0f0f"
      >
        <ModalCloseButton fontSize="12px" _focusVisible={{}} />

        <Box
          m="auto"
          width={`${containerSize.width}px`}
          height={`${containerSize.height}px`}
          overflow="hidden"
          position="relative"
          className={`bb-img-parent ${WEBCAM_CONTAINER_CLASS}`}
        >
          {blackBoard && (
            <Rnd
              bounds=".bb-img-parent"
              size={rndSize}
              minWidth={MIN_BLACKBOARD_WIDTH}
              minHeight={MIN_BLACKBOARD_HEIGHT}
              position={pos}
              onDragStop={onDragStop}
              onResizeStop={onResizeStop}
              enableResizing={enableResizing}
              lockAspectRatio
              onResize={onResize}
              style={{ zIndex: 1 }}
            >
              {blackboardTemplateProps?.blackboardTemplate && (
                <BlackboardTemplateImage
                  blackboardTemplateProps={{
                    ...blackboardTemplateProps,
                    isOnlyView: true,
                  }}
                  blackboardData={{ ...blackBoard, shootingTime: new Date() }}
                  data={blackboardTemplateProps?.blackboardTemplate}
                  contentType={ContentType.BLACKBOARD_TEMPLATE}
                  pointerEvents="none"
                />
              )}
            </Rnd>
          )}

          {isLoadedMedia && !!scale.width && !!scale.height && (
            <Webcam
              audio={false}
              ref={webcamRef}
              id="video"
              width={`${containerSize.width}px`}
              height={`${containerSize.height}px`}
              minScreenshotWidth={containerSize.width}
              minScreenshotHeight={containerSize.height}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: scale.width,
                height: scale.height,
                facingMode: "environment",
              }}
              onUserMediaError={onCameraError}
              style={styleWebcame}
              onLoadedData={() => {
                setIsLoadedCamera.on();
              }}
            />
          )}
          {!isLoadedCamera && (
            <Spinner
              position="absolute"
              top="50%"
              left="50%"
              color="gray"
              size="xl"
            />
          )}
          {enableGrid && (
            <Flex position="absolute" top={0} left={0} right={0} bottom={0}>
              <Divider orientation="vertical" height="100%" ml="33.3%" />
              <Divider orientation="vertical" height="100%" ml="33.3%" />
              <Divider
                position="absolute"
                orientation="horizontal"
                width="100%"
                left={0}
                top="33.3%"
              />
              <Divider
                position="absolute"
                orientation="horizontal"
                width="100%"
                left={0}
                top="67%"
              />
            </Flex>
          )}
          <Flex
            direction="column"
            position="absolute"
            zIndex="2"
            left={{ base: "6vw", lg: "6.5rem" }}
            top={{ base: "4vw", lg: "3rem" }}
            rounded="0.8rem"
            w="6rem"
            h="6rem"
            alignItems="center"
            justifyContent="center"
            bgColor="#fff"
            color="#707070"
            filter="brightness(96%)"
            onClick={handleClose}
            _hover={{ cursor: "pointer" }}
          >
            <SvgIcon src="/img/icon-navigation-close.svg" pathFill="#707070" />
            戻る
          </Flex>
          {isCreateTask && (
            <Flex
              position="absolute"
              zIndex="2"
              top={{ base: "4vw", lg: "3rem" }}
              right={{ base: "6vw", lg: "6.5rem" }}
              direction="column"
              justifyContent="center"
              alignItems="center"
              w="12rem"
              h="6rem"
              bgColor="#fff"
              rounded="1rem"
              cursor="pointer"
              onClick={onCameraError}
              fontWeight="500"
            >
              <SvgIcon
                w="2.8rem"
                h="2.8rem"
                src="/img/icon-skip.svg"
                fill="#707070"
              />
              <Text color="#707070">撮影をスキップ</Text>
            </Flex>
          )}
          <Button
            onClick={setEnableGrid.toggle}
            position="absolute"
            zIndex="2"
            right={{ base: "2.5rem", md: "3.5rem", lg: "6rem" }}
            top={{ base: "calc(50% - 10rem)", lg: "calc(50% - 13rem)" }}
            bg="rgba(0,0,0,0.4)"
            borderRadius="50%"
            p="1rem"
            h={{ base: "4rem", md: "5rem", lg: "5.5rem" }}
            w={{ base: "4rem", md: "5rem", lg: "5.5rem" }}
          >
            <Box
              w="1.5rem"
              h="2.5rem"
              borderLeft="1px solid yellow"
              borderRight="1px solid yellow"
            ></Box>
            <Box
              position="absolute"
              w="2.5rem"
              h="1.5rem"
              borderTop="1px solid yellow"
              borderBottom="1px solid yellow"
            ></Box>
          </Button>
          <Box
            position="absolute"
            zIndex="2"
            right={{ base: "2rem", md: "3rem", lg: "5rem" }}
            top={{ base: "calc(50% - 2rem)", lg: "calc(50% - 3rem)" }}
          >
            <Button
              isLoading={!isLoadedCamera}
              onClick={handleCapture}
              border={{ base: "4px solid #fff", lg: "6px solid #fff" }}
              borderRadius="50%"
              background="#cccccc"
              width={{ base: "5rem", md: "6rem", lg: "7rem" }}
              height={{ base: "5rem", md: "6rem", lg: "7rem" }}
              position="relative"
            >
              <Box
                bg="#fff"
                borderRadius="50%"
                position="absolute"
                left={{ base: "4px", lg: "6px" }}
                top={{ base: "4px", lg: "6px" }}
                width={{ base: "calc(100% - 8px)", lg: "calc(100% - 12px)" }}
                height={{ base: "calc(100% - 8px)", lg: "calc(100% - 12px)" }}
              />
            </Button>
          </Box>

          <Box
            position="absolute"
            zIndex="2"
            top={{
              base: "calc(50% - 5rem)",
              md: "calc(50% - 9rem)",
              lg: "calc(50% - 10rem)",
            }}
            left={{ base: "2rem", md: "4rem", lg: "6rem" }}
          >
            <Slider
              aria-label="slider-ex-3"
              defaultValue={0}
              orientation="vertical"
              minH={{ base: "10rem", md: "18rem", lg: "20rem" }}
              onChange={handleZoomInOut}
            >
              <SliderTrack
                bg="#000"
                border="1px solid #fff"
                w="8px"
                boxShadow="base"
              >
                <SliderFilledTrack bg="yellow" />
              </SliderTrack>
              <SliderThumb
                borderWidth="2px"
                borderColor="yellow"
                bg="transparent"
                boxSize={"2rem"}
              />
            </Slider>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default CameraModal;
